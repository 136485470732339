.admin-game-manager-container{
    display: flex;
    gap: 2rem;
}

.admin-game-manager{
    width: 45%;
    margin-top:3rem;
    height: 50vh;
    min-height: 4rem;
    display: flex;
    flex-direction: column;
}

.admin-game-manager:first-child{
    width: 55%;
}

.game-table{
    display: flex;
    flex-direction: column;
    gap: 1rem;
    overflow: auto;
    padding-right: 1rem;
}

.game-item, .topper {
    background-color: var(--lightFrostedBackground);
    display: grid;
    grid-template-columns:1fr 1.5fr 1fr 1.5fr;
    padding: .6rem 1rem;
    gap: 1rem;
}

.topper{
    position: sticky;
    top: 0px;
    left: 0px;
    background: #2d2d39;
}


.game-item > div, .topper > div{
    display: flex;
    width: 100%;
    border-right: var(--fros);
}

.game-item > div:last-child, .topper > div:last-child{
    border-right: none;
}

.game-item > div > h5{
    font-weight: 600;
    font-size: .9rem;
}

.game-item{
    cursor: pointer;
}

.game-item > div > h5{
    color: var(--white);
    font-weight: normal;
}


.topper > div > h5{
    font-weight: bold;
    font-size: .9rem;
    cursor: pointer;
}

.topper >div> h5:hover {
   opacity: 0.7;
}

.topper > div > h5{
    color: var(--white);
}

.table-items{
    gap: 1rem;
    display: flex;
    flex-direction: column;
    overflow: auto;
}



.admin-geneal-game-info{
    display: flex;
    gap: 1rem;
    margin-bottom: 1rem;
}

.admin-geneal-game-info > div{
    display: flex;
    align-items: center;
}

.admin-geneal-game-info > button:first-of-type{
    margin-left: auto;
}

.admin-geneal-game-info > div > h5{
    font-weight: 600;
    font-size: .9rem;
}

.admin-geneal-game-info > div > h5:last-child{
    margin-left: .5rem;
    color: var(--main-color);
}

.admin-add-game{
    background: var(--main-color);
    border: none;
    border-radius: 5px;
    cursor: pointer;
    font-size: var(--px13);
    font-weight: 600;
    padding: 6px 6px;
}

.no-game-selected{
    margin: auto;
}

.lobby-info{
    display: flex;
    flex-direction: column;
    gap: 1rem;
    overflow: auto;
    padding-right: 1rem;
}

.admin-players{
    gap: 1rem;
    display: flex;
    flex-wrap: wrap;
}

.admin-player-card{
    background-color: var(--lightFrostedBackground);
    padding: 15px;
    width: 180px;
    border-radius: 7px;
}


.admin-player-card-team{
    background-color: var(--lightFrostedBackground);
    padding: 15px;
    width: 275px;
    border-radius: 7px;
    display: flex;
    justify-content: space-between;
}

.admin-player-card-team img{
    width: 30px;
    height: 30px;
}

.admin-player-card-team h2{
    font-size: var(--px13);
    max-width: 115px;
}

.admin-player-card img {
    width: 30px;
    height: 30px;
}

.admin-player-card h2{
    font-size: var(--px13);
    max-width: 115px;
}

.selected-lobby{
    
    display: flex;
    flex-direction: column;
    overflow: hidden;

}