.entry-nav {
display: flex;
padding: 15px;;
align-items: center;
gap: 70px;
}

.entry-nav-logo {
    pointer-events: none;

}

.entry-nav-links {
display: flex;
list-style-type: none;
gap: 40px;
font-size: var(--px16);
}

.top-landing {   display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: 100vh;width: 100%;}

.landing-title {width: 650px;}

.l-title-text > h1 {
    font-size: 50px;
    font-weight: 700;
    text-transform: uppercase;
    line-height: 60px;
}

.l-title-text > h1 > span {
    color: var(--main-color);
}

.l-title-text > p {
    font-size: 19px;
    font-weight: 600;
    color: var(--light-text);
}

.l-title-text > p > span {
    color: var(--main-color);
    font-weight: bold;
}

.l-title-buttons {margin-top: 20px;}

.community-link {}

.community-link > a > img {
    width: 210px;
    border-radius: 10px;

}

.community-link > a > img:hover {
   background-color: var(--frostedBackground);
   outline: var(--frostedBorder);
}