.play-zone {
    display: flex;
    padding: 2em;
    height: 50em;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    transition: 0.2s ease;
}

.extented-play {
    height: 47em
}


.notification-spacer {
    width: 100%;
    height: 70px;
}

.game-next {
    display: none;
}

.game-desc-container>.back-title {
    width: 40em;
}

.game-body {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: 100vh;
}

.game-container {
    width: 100%;
    display: flex;
    align-items: center;
}

.left-gamebar {
    display: flex;
    align-items: center;
    gap: 35px;
}

.gamebar-container {
    display: flex;
    margin-top: 1.875em;
    justify-content: space-between;
    position: relative;
    z-index: 14;
}

.right-gamebar {
    display: flex;
    align-items: center;
 
}

.game-desc {
    width: 22.5em;
    margin-top: -1.3125em;
    margin-left: -0.3125em;
}

.game-desc>p {
    color: var(--main-color);
    text-transform: uppercase;
    font-weight: 700;
    font-size: var(--px20);
    margin-bottom: -0.5em;
}

.game-footer {
    display: flex;
    width: 100%;
    margin-bottom: 2.8125em;
    gap: 2.1875em;
    position: relative;
    z-index: 150;
    align-items: flex-end;
    height: 70px;

}

.game-help {
    width: 90%;
    background-color: var(--darkFrostedBackground);
    padding: 1.25em 1.8125em;
    border-radius: 0.625em;
    margin-left: -0.3125em;
    opacity: 0;
}

.game-help>p {
    font-size: var(--px14);
    line-height: 1.4375em;
}

.game-help>h1 {
    font-size: var(--px20);
    margin-bottom: 0.4375em;
}

.exit-btn>button {
    background-color: transparent;
    border: none;
    display: flex;
    align-items: center;
    gap: 0.625em;
    cursor: pointer;
   
}

.exit-btn>button>i {
    background-color: var(--frostedBackground);
    padding: 0.3em;
    border-radius: 3.125em;
    border: var(--frostedBorder);
    transition: 0.2s ease;
}

.exit-btn>button:hover>i {
    background-color: var(--alt-color4)
}

.exit-btn>button>span {
    font-size: var(--px17);
    font-weight: 600;
}

.back-btn > button{
    display: flex;
    align-items: center;
    gap: 0.625em;
    cursor: pointer;
    background-color: var(--frostedBackground);
    border: var(--frostedBorder);
    padding: 7px 20px;
    font-weight: 600;
    border-radius: 50px;
    transition: 0.2s ease;
}

.back-btn > button > i {margin-left: -6px;}

.back-btn > button:hover {
    background-color: var(--main-color);
    box-shadow: var(--lightShadow);
  
 
}

.back-btn > button:hover > span {
    color: black;
}

.back-btn > button:hover > i {
    color: black;
}


.game-nav-footer {
    display: flex;
    align-items: flex-end;
    width: 100%;
    gap: 0.875em;
    justify-content: space-between;
    max-width: 350px;
}

.game-nav-footer>a>button {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: var(--darkFrostedBackground);
    border: none;
    padding: 0.75em;
    font-size: var(--px16);
    font-weight: 600;
    color: var(--light-text);
    border-radius: 0.375em;
    cursor: pointer;
    text-transform: uppercase;
}

.game-nav-footer>a:last-of-type>button {
    width: 100%;
    background-color: var(--main-color);
    color: black;
    box-shadow: var(--deepShadow);
    transition: 0.2s ease;
}

.game-nav-footer>a:last-of-type>button:hover {
    box-shadow: var(--lightShadow);
    outline: var(--filledBorder);
    background-color: transparent;
    color: var(--main-color);
}

.game-nav-footer>a:last-of-type>button:hover>i {
    color: var(--main-color);
}


.game-nav-footer>a {
    width: 100%;
    display: flex;
    justify-content: center;
}

.game-nav-footer>a:last-of-type>button>i {
    color: black;
}


.sheet {}

.game-sheet {
    width: 33em;
    height: fit-content;
    border-radius: 0.625em;
    ;
    box-shadow: var(--deepShadow);
    transition: 0.2s ease;
    padding-bottom: 1.2em;
    padding-top: 1em;
}





.defend-img:after {
    background-color: rgba(16, 16, 21, 0.621);
    backdrop-filter: blur(8.125em);
}

.top-strip {
    display: flex;
    justify-content: space-evenly;

    font-size: var(--px20);
    font-weight: 600;
    margin-left: 1.3em;
    width: 100%;
    gap: 2em;
    margin-bottom: 0.6em;
}

.top-strip>span {
    color: #ffffff3d;
}

.sheet-zone {
    display: flex;
    gap: 1.2em;
    padding: 0em 1.2em;
}

.side-strip {
    display: flex;
    flex-direction: column;
    display: flex;
    justify-content: space-between;
    flex-direction: column;
    height: 36em;
    margin-bottom: 4em;
}

.side-strip>span {
    color: #ffffff3d;
    ;
    font-weight: 600;
    font-size: var(--px20);
}

.sheet-grid {
    cursor: pointer;
}

.sheet-grid {
    display: flex;
    width: 28em;
}

.grid-cell > label {
    background-color: rgb(0 0 0 / 13%);
    display: block;
    width: 100%; 
    height: 100%;
}

.grid-cell:hover {
    cursor: pointer;
    outline: var(--filledBorder);
    position: relative;
    z-index: 15;
    box-shadow: var(--lightShadow);
    border-radius: 0.125em;
}

.grid-cell {
    height: 4.15em;
    cursor: pointer;
    outline: 0.0625em solid transparent;
    transition: 0.2s ease;
}

.grid-cell>label>span {
    width: 100%;
    display: block;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
}

.grid-cell>input {
    display: none;
}

.grid-cell>input:checked+label>span {
    background-color: #ffffff1c;
    background: linear-gradient(90deg, var(--main-color) 50%, transparent 50%),
        linear-gradient(90deg, var(--main-color) 50%, transparent 50%),
        linear-gradient(0deg, var(--main-color) 50%, transparent 50%),
        linear-gradient(0deg, var(--main-color) 50%, transparent 50%);
    background-repeat: repeat-x, repeat-x, repeat-y, repeat-y;
    background-size: 3em 0.24em, 3em 0.24em, 0.24em 3em, 0.24em 3em;
    background-position: 0% 0%, 100% 100%, 0% 100%, 100% 0em;

    padding: 0.625em;
    animation: dash 1s linear infinite;
    box-shadow: var(--lightShadow);
    position: relative;
    z-index: 100;
  
}

.grid-cell > label > p {display: none;}

.grid-cell>input:checked+label {
    display: block;
    width: 100%;
    height: 100%;
    background: linear-gradient(112.18deg, var(--main-color) -0.1%, #000000ac -0.09%, var(--main-color) 130%);
    opacity: 1 !important;
}

.grid-cell>input:checked+label:hover {
    background: linear-gradient(112.18deg, var(--main-color) -0.1%, #000000ac -0.09%, var(--main-color) 100%)
}

.grid-cell>input:checked+label>span>.back-sheet {
    display: flex;
    width: 90%;
    height: 2.5em;
    background-color: var(--main-color);
    justify-content: center;
    align-items: center;
}

.back-sheet > p {display: none; 
    font-weight: 600;
    color: black;
    font-size: var(--px16);
    text-transform: uppercase;display: none !important;}

.grid-cell>input:checked+label>span>.back-sheet>p {display: block;}



/* .grid-cell > input:checked + label > span {background: linear-gradient(112.18deg, #26F980 -0.1%, #00200E -0.09%, #008036 93.95%);position: relative; z-index: -1;}
 */
.column {
    width: 100%;
    display: flex;
    width: 100%;
    flex-direction: column;
    justify-content: space-between;
}

.a-column {
    background-color: #112232b7;
}

.b-column {
    background-color: #172d41b7;
}

.c-column {
    background-color: #14283Ab7;
}










.sheet-date {
    display: flex;
    justify-content: space-between;
    margin-top: 0.75em;
    align-items: center;
}

.sheet-stepper>button {
    display: flex;
    padding: 0.4em;
    align-items: center;
    justify-content: center;
    background-color: var(--frostedBackground);
    border: none;
    font-weight: 600;
    border-radius: 2em;
    cursor: pointer;
    font-size: var(--px16);
}

.day-next > button  {background-color: var(--main-color);padding-left: 0.9375em;}

.sheet-desc > div > p {
    font-size: var(--px16);
    font-weight: 700;
    color: white;
   

}







.game-overview-container {
    display: flex;
    justify-content: space-between;
    position: relative;
    z-index: 1;
}

.match-footer {height: 115px;}














.game-overview-container > div {display: flex;justify-content: center;flex-direction: column;margin-bottom: 55px;margin-top: -50px;}

.game-overview {display: flex; ;flex-direction: column; 
    gap: 30px;
    width: 415px;
}

.over-card {background-color: var(--frostedBackground);border: var(--frostedBorder);padding: 23px;border-radius: 8px;}



.over-card > h2 {font-size: var(--px16);font-weight: 700;color: white;text-transform: uppercase; width: 100%; text-align: center;padding-bottom: 20px;
    border-bottom: var(--frostedBorder);}

.split-card {display: flex;
    gap: 25px;}

.circle-date {
    display: flex;
    flex-direction: column;
    width: 90px;
    background-color: var(--frostedBackground);
    height: 90px;
    border-radius: 60px;
    justify-content: center;
    align-items: center;
    gap: 0px;
}

.circle-date > h2 {font-size: 30px;font-weight: 700;color: var(--main-color);text-transform: uppercase;margin-bottom: -7px;}

.over-card-content {
    margin-top: 22px;
}

.split-right {display: flex; flex-direction: column;gap: 20px;}

.split-right > div>  h3 {font-size: var(--px20);font-weight: 700;color: white;text-transform: uppercase;}

.split-right > div>  p {font-size: var(--px15);
    font-weight: normal;
    color: white;
    line-height: 25px;}

.center-card {    display: flex;
    flex-direction: column;
    gap: 36px;
    padding-top: 22px;}

    .center-card > p {    font-size: var(--px14);
        text-align: center;
    }

.center-text {display: flex; flex-direction: column; align-items: center; justify-content: center;}

.center-text > h2 {    font-size: 40px;
    color: var(--main-color);
    margin-top: -7px;
    margin-bottom: -4px; }

.center-text > h3 {color: var(--native-color);}


.center-text > p {font-size: var(--px15);}

.massive-button-container {display:flex; justify-content: center;}

.massive-button {display: flex;
    justify-content: center;
    align-items: center;
    gap: 10px;
    width: 95%;
    padding: 15px;
    text-transform: uppercase;
    background-color: var(--main-color);
    color: black;
    font-weight: 700;
    font-size: var(--px16);border: none; border-radius: 6px;box-shadow: var(--lightShadow);cursor: pointer;transition: 0.2s ease;}

.massive-button > i {color: black;}

.massive-button:hover {box-shadow: var(--deepShadow);background-color: transparent;outline:var(--filledBorder);color: white;}

.massive-button:hover > i {color: white;}

.over-card-footer {position: sticky;
    bottom: 0px;
    backdrop-filter: blur(10px);}
    .over-card-footer > p {text-align: center;
        font-size: var(--px13);
        padding: 11px 64px;
    }

.over-card-footer > p > button {background-color: transparent; border: none; text-decoration: underline; color: var(--main-color);font-weight: 600;cursor: pointer;}


@keyframes dash {
    to {
        background-position: 100% 0%, 0% 100%, 0% 0%, 100% 100%;
    }
}




/* ------------------Mini Map------------------ */

.minimap {    display: flex;
    justify-content: center;
   width: 100%;padding-bottom: 11px;}

.attack-mini {background-color: var(--main-color);}

.minimap-container {width: 295px;
    margin-left: -269px;
    display: flex;
    padding-right: 59px;
    flex-direction: column;
    justify-content: flex-end;}

.minimap-wrapper {display: flex;flex-direction: column;justify-content: flex-end;background-color: var(--frostedBackground);border-radius: 9px;border: var(--frostedBorder);margin-bottom: 17px;}



.mini-column {width: 30%;    font-size: var(--px15);
    font-weight: 600;
    overflow: hidden;
 
    display: inline-block;
    text-overflow: ellipsis;
    white-space: nowrap;}


.minimap-header { display: flex;
    justify-content: space-around;
    padding: 7px 0px;
    cursor: pointer;
}



.mini-column > p  {transition: 0.2s ease;font-size: var(--px13);height: 20px;text-align: left;font-weight: 600;color: var(--main-color);    font-size: var(--px15);
    font-weight: 600;
    overflow: hidden;
 width: 100%;
    text-overflow: ellipsis;
    white-space: nowrap;cursor: default;display: flex;
    gap: 6px;}

    .minimap-wrapper > p {    text-align: center;
        font-size: 12px;
        padding: 7px 21px;
        margin-top: -10px;display: none;transition: 0.2s ease;}

.minimap-column-a  {background-color: #112232b7;}

.mini-column > p > div {display: flex;justify-content: center;font-weight: normal;margin-bottom: -6px;width: 100%;
    overflow: hidden;
 
    display: inline-block;
    text-overflow: ellipsis;
    white-space: nowrap;}

.foc-active {    border: var(--frostedBorder);border-radius: 5px;padding: 0px 5px;position: relative; z-index: 15;background-color:var(--frostedBackground);
}



.foc-kill {background-color: var(--alt-color4) !important;box-shadow: none;}

.mini-column > p > span {display: flex; font-weight: 600; align-items: center;justify-content: center;width: 100%;}

.minimap-column-b {background-color: #172d41b7;}

.minimap-column-c {background-color: #14283Ab7;}

.selected-mini {opacity: 1 !important;background-color: var(--main-color);}

.minimap-header > h3 {display: flex; align-items: center;font-size: var(--px14);gap: 5px;}

.minimap-header > h3 > i {color: var(--main-color);}

.minimap-action {background-color: transparent;display: flex; align-items: center;border: none;cursor: pointer;}

.minimap-action > i {border-radius: 50px;}

.minimap-action > i:hover {background-color: var(--main-color);}

.auto-place-btn {    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    z-index: 2;
    /* margin-left: 8px; */
    margin-top: -1px;
    padding: 0px 12px 12px 12px;
  
}

.auto-place-btn>button {
    background-color: #ffffff1c;
    border: none;
    display: flex;
    align-items: center;
    gap: 5px;
    cursor: pointer;
    color: white;
    font-weight: 600;
    padding: 5px 9px;
    border-radius: 5px;
    transition: 0.2s ease;
 
}

.auto-place-btn>button>i {
 
}

.auto-place-btn>button:hover>i {
    color:black
}

.auto-place-btn>button:hover {
    color: black;
    background-color:var(--main-color);
    
}












@media screen and (max-height: 850px) {
   .side-strip {margin-bottom: 2.85em;}
   .grid-cell {height: 3.87em;}
   .game-sheet {padding-bottom: 0em;}
   .game-desc-container {margin-top: -11.8em;}
   .game-container {gap: 0.5em;}
   .game-overview-container > div {    justify-content: flex-start;margin-top:30px;}
    
}

@media screen and (max-height: 960px) {
    .minimap-wrapper {margin-bottom: 83px;}
    .minimap-container {padding-right: 66px;}
}