.back-plate-container > img {position: fixed; left: 0px; width: 100%; object-fit: cover; top: 0px; height: 100vh;z-index: -1;;}

.menu-body {     display: flex;
  justify-content: center;
  flex-direction: column;
  max-width: 1300px;
  /* align-items: center; */
  margin: auto;}

.text-action {width: 50%;
    display: flex;
    flex-direction: column;
    gap: 7px;}

    

.text-action > span {font-size:  var(--px13);color: var(--light-text);margin-top: 10px;}

.text-action > span > a {color: var(--main-color);font-weight: 600;cursor: pointer;}

.text-action > span > a:hover {text-decoration: underline;}

.mode-wrapper {padding-right: 55px;}

.mode-item {width: 451px;
    height: 260px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 10px;
    background-size: cover;
    background-position: center;
    position: relative;
    z-index: 14;
    background-color: black;}

    .mode-text > h3 {font-size: 24px;}

    .mode-text > p  {display: flex ; align-items: center;justify-content: center; gap: 6px;}

    .mode-text > p {font-size: var(--px17);font-weight: 600;text-align: center;}

    .mode-text > p > span {font-size: var(--px14);}

.text-action > p {font-size: var(--px14);}

.priority-btn-wrapper {display: flex; gap: 15px;margin-top: 25px;}

.priority-btn-wrapper > button {    width: 40%;
    padding: 12px 6px;
    font-size: var(--px15);
    font-weight: 600;
    background-color: var(--darkFrostedBackground);
    border: none;
    border-radius: 7px;cursor: pointer;}

.priority-btn-wrapper > button:first-of-type {background-color: var(--main-color);width: 60%;}



@-webkit-keyframes rotate {
    100% {
      transform: rotate(1turn);
    }
  }
  
  @keyframes rotate {
    100% {
      transform: rotate(1turn);
    }
  }
  .rainbow {
    position: relative;
    z-index: 0;
    background-color: transparent;
    border-radius: 10px;
    overflow: hidden;
    padding: 4px;
    box-shadow: var(--deepShadow);
  }
  .rainbow::before {
    content: "";
    position: absolute;
    z-index: -2;
    left: -50%;
    top: -50%;
    width: 200%;
    height: 200%;
    background-color: transparent;
    background-repeat: no-repeat;
    background-size: 50% 50%, 50% 50%;
    background-position: 0 0, 100% 0, 100% 100%, 0 100%;
    background-image: linear-gradient(var(--main-color), var(--main-color)), linear-gradient(transparent, transparent), linear-gradient(var(--main-color), var(--main-color)), linear-gradient(transparent, transparent);
    -webkit-animation: rotate 20s linear infinite;
            animation: rotate 20s linear infinite;
  }
  .rainbow::after {
    content: "";
    position: absolute;
    z-index: -1;
    left: 6px;
    top: 6px;
    width: calc(100% - 12px);
    height: calc(100% - 12px);
    background: transparent;
    border-radius: 5px;
  }






  .rainbow-fast {
    position: relative;
    z-index: 0;
    background-color: transparent;
    border-radius: 10px;
    overflow: hidden;
    padding: 4px;
    box-shadow: var(--deepShadow);
  }
  .rainbow-fast::before {
    content: "";
    position: absolute;
    z-index: -2;
    left: -50%;
    top: -50%;
    width: 200%;
    height: 200%;
    background-color: transparent;
    background-repeat: no-repeat;
    background-size: 50% 50%, 50% 50%;
    background-position: 0 0, 100% 0, 100% 100%, 0 100%;
    background-image: linear-gradient(var(--main-color), var(--main-color)), linear-gradient(transparent, transparent), linear-gradient(var(--main-color), var(--main-color)), linear-gradient(transparent, transparent);
    -webkit-animation: rotate 5s linear infinite;
            animation: rotate 5s linear infinite;
  }
  .rainbow-fast::after {
    content: "";
    position: absolute;
    z-index: -1;
    left: 6px;
    top: 6px;
    width: calc(100% - 12px);
    height: calc(100% - 12px);
    background: transparent;
    border-radius: 5px;
  }
  
